import React from 'react';
import Layout from '../components/global/layout/layout';
import AirtableForm from '../components/global/airtable-form/airtable-form';

const TourPage = () => (
  <Layout>
    <AirtableForm title="Guided tour booking form" formId="shrS2Y0uJzdnbePga" />
  </Layout>
);

export default TourPage;
